import React from "react"
import Layout from "../components/layout"

const Essays = () => {
  return (
    <Layout>
      <section>Essays/Press</section>
    </Layout>
  )
}

export default Essays
